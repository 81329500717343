import React, { useState } from 'react';
import Layout from '../Main/Utilities/Layout'

import Line from '../Static/Line.svg';
import Line1 from '../Static/Line1.svg';
import Pic1 from '../Static/blog1.svg';
import Pic2 from '../Static/blog2.svg';
import Pic3 from '../Static/blog3.svg';

// CSS import
import '../Styles/faq.css';

export default function FAQ() {

  const [show,setShow]=useState(false)
  const [show1, setShow1]=useState(false)
  const [show2, setShow2]=useState(false)
  const [show3, setShow3]=useState(false)
  const [show4, setShow4]=useState(false)
  const [show5, setShow5]=useState(false)
  const [show6, setShow6]=useState(false)
  const [show7, setShow7]=useState(false)
  const [show8, setShow8]=useState(false)
  const [show9, setShow9]=useState(false)
  const [view, setView]=useState(false)
  const [view1, setView1]=useState(false)
  
  return (
      <Layout>
        <div>
          <section id='FAQ'>
            <div className='faq-contents ptFaq'>
              <h1 className='texts1'>FAQ</h1>
              <div>
                <button className='click-btn' onClick={()=>setShow(!show)}><p className='flex-texts'>How can I buy HOUZ tokens?<img className='wi' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show?<p className='about-text size'>At this stage, there are two ways to buy HOUZ tokens: Via Private Sale and through the Launchpads. To invest privately, please send us an email sales@cryptoracetrack.com. To buy tokens in the IDO, follow us on Twitter or join our Telegram & Discord channels to be updated on IDO dates and platforms (soon to be announced)</p>:null
                }        
              </div> <br/> <br/>
              <div>
                <button className='click-btn' onClick={()=>setShow1(!show1)}><p className='flex-texts'>How can I earn by holding HOUZ tokens?<img className='line1' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show1?<p className='about-text size'>
                    There are two ways an early adopter wins:
                    <ul>
                      <li>Earn a % of the betting profits made by the CryptoRaceTrack House. After every race, the betting profits will be distributed to HOUZ holders proportionally. The more tokens you HOLD, the bigger your cut.</li>
                      <li>Enjoy the natural price increase of HOUZ when traded in the open market.</li>
                    </ul>
                  </p>:null
                }        
              </div> <br/><br/>
              <div>
                <button className='click-btn' onClick={()=>setShow2(!show2)}><p className='flex-texts'>How many HOUZ tokens will be minted, and how many are available at this stage?<img src={Line1} alt='line'/><i className='fa-solid fa-angle-down'></i> </p></button>
                {
                  show2?<p className='about-text size'>There will be a total supply of 1 Trillion (1,000,000,000,000) HOUZ tokenbs. At this stage,
                  we are offering 20% of the tokens for Early Investors to buy through our Private Sale and the
                  IDO Launchpads</p>:null
                }        
              </div> <br/> <br/>
              <div>
                <button className='click-btn' onClick={()=>setShow3(!show3)}><p className='flex-texts'>What other coins exist in CryptoRaceTrack, besides HOUZ?<img className='line2' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show3?<p className='about-text size'>Our Metaverse Economy is supported in several coins that enable Fans, Creators, and
                  Owners to participate, have fun and make money:
                  <ul>
                      <li>CHIP is the official coin within the CRT Metaverse, and is used for all money transactions. It's a stable coin pegged to the US Dollar.</li>
                      <li>FAN coin is a governance token made to give the fans the voting power to improve the CRT Metaverse according to the community's wishes. For example, vote on which Crypto Racing Teams will compete in a “Fan Race”.</li>
                      <li>Team Owner tokens will provide Fans an opportunity to own a piece of a racing Team. Own your favorite Team and earn money every race it gets a place in the podium.</li>
                      <li>Track Owner tokens work the same way, but with tracks. If you love the track, you can own a piece of it, and earn rent every there is a race in your track.</li>
                      <li>Racing Asset tokens will be introduced to represent 3D designs for Ships, Team Logos, and Team Driver.</li>
                      <li>Metaverse Asset tokens will be introduced to represent live-event tickets, and Publicity Space in the Virtual RaceTracks.</li>
                    </ul>
                 </p>:null
                }        
              </div> <br/> <br/>
              <div>
                <button className='click-btn' onClick={()=>setShow4(!show4)}><p className='flex-texts'>How do I place a bet?<img className='line3' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show4?<p className='about-text size'>You must log in to CRT with your Binance or Metamask wallet, exchange some crypto for
                  CHIPS, pick your fav crypto asset in the race, and place a bet at the Betting Office. That's it,
                  now you just relax and enjoy the race!
                 </p>:null
                }        
              </div> <br/> <br/>
              <div>
                <button className='click-btn' onClick={()=>setShow5(!show5)}><p className='flex-texts'>How do I know the bet is transparent and there won't be any foul play?<img className='line4' src={Line1} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show5?<p className='about-text size'>“Bets Operation Control” will be fully decentralized via a series orchestrated fully-auditable
                  BSC Smart Contracts that interact with different stakeholder's BSC Wallets. As a result, bets
                  will be recorded as a Smart Contract in the Binance Smart Chain; both the bettor and the
                  House's CHIPs will be held in escrow in a named wallet; and the bet payout mechanism will
                  be pre-programed in a Smart Contract to pay according to the final podium for the race
                  (which will be supported on Binance's crypto price data feed).</p>:null
                }        
              </div> <br/> <br/>
              <div>
                <button className='click-btn' onClick={()=>setShow6(!show6)}><p className='flex-texts'>How do FAN tokens work?<img className='wi' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show6?<p className='about-text size'>Fan tokens are the way CRT brings power to the community. Fan tokens holders will have
                  voting power to create Fan Races and chose which teams participate; will be able to change
                  the look and feel of the Official Team cars, logos, drivers, and so much more. Fan token
                  holders are the priority for CRT and their wishes will be heard and served through the voting
                  events.
                 </p>:null
                }        
              </div> <br/> <br/>
              <div>
                <button className='click-btn' onClick={()=>setShow7(!show7)}><p className='flex-texts'>If I miss the race, how can I know if I won?<img className='line1' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show7?<p className='about-text size'>
                    Profits (and losses) are executed immediately after the race finishes. So, you can check if
                    you won by giving a look at your wallet balance. You can also check the Race Board for
                    race results, and you can also watch a replay of the race
                  </p>:null
                }        
              </div> <br/><br/>
              <div>
                <button className='click-btn' onClick={()=>setShow8(!show8)}><p className='flex-texts'>How can I participate in the CRT Marketplace?<img className='line5' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show8?<p className='about-text size'>
                    You can be an NFT artist at the CRT Marketplace by bringing your designs and opening a
                    shop. Your NTFs will be open for everyone in the CRT Metaverse to interact with them. Your
                    designs can be voted by the Fans to become official Team Racing Assets (ships, drivers,
                    logo). Also, you can collect, buy, sell and profit from CRT official paraphernalia.
                  </p>:null
                }        
              </div> <br/><br/>
              <div>
                <button className='click-btn' onClick={()=>setShow9(!show9)}><p className='flex-texts'>Can I take my NFTs to any other metaverse?<img className='line5' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  show9?<p className='about-text size'>
                    Yes. We are metaverse enthusiasts and believe in compatibility. Therefore, you are free to
                    move and enjoy anywhere.
                  </p>:null
                }        
              </div> <br/><br/>
              <div>
                <button className='click-btn' onClick={()=>setView(!view)}><p className='flex-texts'>What benefits do I get by becoming a racing team owner?<img className='line2' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  view?<p className='about-text size'>You will earn profits every time your racing team gets a place on the podium. Also, you'll hold
                  the power to change the Official Team's ship, the driver, the colors, and anything the 
                 community suggests at the voting events.    
                 </p>:null
                }        
              </div> <br/> <br/>
              <div>
                <button className='click-btn' onClick={()=>setView1(!view1)}><p className='flex-texts'>Is there anything more fun than betting at CryptoRaceTrack?<img className='line2' src={Line} alt='line'/><i className="fa-solid fa-angle-down"></i> </p></button>
                {
                  view1?<p className='about-text size'>We don't think so. The excitement of the race and watching your fav crypto winning is worth
                  it. Well... money is appealing too. 
                 </p>:null
                }        
              </div> <br/> <br/>
            </div>
          </section>
          <section id='Blog'>
            <div className='faq-contents'>
              <h1 className='texts1'>Blog</h1>
              <div className='flex-blog'>
                <div className='blog-post'>
                  <p className='text-class'>La experiencia de usar dial-up en la Web moderna</p>
                  <img className='blogImage' src={Pic1} alt='blog post'/>
                  <p className='about-text textalign'>Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño.</p>
                </div>
                <div className='blog-post'>
                  <p className='text-class'>La experiencia de usar dial-up en la Web moderna</p>
                  <img className='blogImage' src={Pic2} alt='blog post'/>
                  <p className='about-text textalign'>Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño.</p>
                </div>
                <div className='blog-post'>
                  <p className='text-class'>La experiencia de usar dial-up en la Web moderna</p>
                  <img className='blogImage' src={Pic3} alt='blog post'/>
                  <p className='about-text textalign'>Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño.</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    
  )
}
