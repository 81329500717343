import React, { useState } from "react";

import { Link } from "react-router-dom";
import logoImage from "../../Static/LogoNew.png";

// CSS import //
import "../../Styles/navbar.css";

export default function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  return (
    <div>
      <nav className="navbar">
        <a href="/" className="logo">
          <img className="logoImage" src={logoImage} alt="Companylogo" />
        </a>
        <ul
          className={isMobile ? "nav-links-mobile" : "nav-links"}
          onClick={() => setIsMobile(false)}
        >
          <li>
            <a className="page" href="/">
              Home
            </a>
          </li>
          <li>
            <a className="page" href="#About">
              About
            </a>
          </li>
          <li>
            <a className="page" href="#Experiences">
              Experiences
            </a>
          </li>
          <li>
            <a className="page" href="#Roadmap">
              Roadmap
            </a>
          </li>
          <li>
            <a className="page" href="#Tokenomics">
              Tokenomics
            </a>
          </li>
          <li>
            <a className="page" href="#TokenSale">
              Participate
            </a>
          </li>
          <li>
            <a className="page" href="#Contact">
              Contact
            </a>
          </li>
          <li>
            <Link
              className="page"
              to="/faq"
              rel="noopener noreferrer"
              target="_blank"
            >
              FAQ/BLOG
            </Link>
          </li>
          {/* <li>
            <Link
              className="page"
              to="/team"
              rel="noopener noreferrer"
              target="_blank"
            >
              Team
            </Link>
          </li> */}
        </ul>
        <button
          className="mobile-menu-icon"
          onClick={() => setIsMobile(!isMobile)}
        >
          {isMobile ? (
            <i className="fa-solid fa-xmark"></i>
          ) : (
            <i className="fa-solid fa-bars"></i>
          )}
        </button>
      </nav>
    </div>
  );
}
