import React from "react";

import Twitter from "../../Static/twitter.svg";
import Telegram from "../../Static/telegram.svg";
import Discord from "../../Static/discord.svg";
import Youtube from "../../Static/youtube.svg";
import Github from "../../Static/github.svg";
import Instagram from "../../Static/instagram.svg";

// CSS import
import "../../Styles/footer.css";

export default function Footer() {
  return (
    <div>
      <footer id="footer">
        <div className="footer-sec">
          <div className="footer-flex">
            <a
              href="https://twitter.com/CryptoRaceTrack"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={Twitter} alt="twitter" />
            </a>
            <a
              href="https://t.me/+z-auWhapmMAzMWM5"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={Telegram} alt="telegram" />
            </a>
            <a
              href="https://discord.gg/QjkGUuTDAT"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={Discord} alt="discord" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCWsNotomsl3z1H6JUbFA-2A"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={Youtube} alt="youtube" />
            </a>
            <a
              href="https://github.com/CRTDaddy"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={Github} alt="github" />
            </a>
            <a
              href="https://www.instagram.com/cryptoracetrack/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={Instagram} alt="instagram" />
            </a>
          </div>
          <div className="">
            <a href="https://cryptoracetrack.gitbook.io/terms-and-conditions/" rel="noopener noreferrer" target="_blank">
              <p className="termsCon">Terms and conditions</p>
            </a>
            <p className="madeText">Made with love by CRTrackers</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
