import React, { Suspense } from "react";
import { useGLTF, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

function Show3D() {
  const { scene } = useGLTF("btc.glb");
  return <primitive object={scene} />;
}

function model() {
  return (
    <div
      style={{
        height: "50vh",
        width: "60vw",
        margin: "auto",
        backgroundColor: "#330e85",
      }}
    >
      <Canvas camera={{ position: [70, 80, 4], fov: 80, zoom: 0.3 }}>
      <ambientLight intensity={1} />
        <pointLight  position={[20, 20, 20]} intensity={1} />
        <Suspense fallback={null}>
          <Show3D />
        </Suspense>
        <OrbitControls autoRotate={true} />
      </Canvas>
    </div>
  );
}

export default model;
