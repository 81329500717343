import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import Roadmap1 from '../Static/Roadmap.svg';

class Roadmap extends Component {
    render() {
        return (
            <Carousel showThumbs={false} useKeyboardArrows={true} showIndicators = {false} showStatus= {false}>
                <div>
                    <img src={Roadmap1} alt='roadmap1' />
                </div>
            </Carousel>
        );
    }
}

export default Roadmap;

