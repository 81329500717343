import React from "react";

import YouTubePlayer from "react-player/youtube";
import Roadmap from "./Roadmap";
import TokenSaleStages from "./TokenSaleStages";
import PieChart from "../Static/PieChart.svg";
// import Gitbook from "../Static/gitbook.svg";
import Model from "../Model";
import TokenImg from "../Static/tokenImg.png";
import Mailto from "reactv16-mailto";
import PinkSaleLogo from "../Static/pinkSaleLogo.png";

// CSS imports //
import "../Styles/homepage.css";

export default function Homepage() {
  return (
    <div>
      <section id="Home">
        <div className="background-shadow"></div>
        <div className="home-section">
          <div className="header-text">
            <h1 className="header">Virtual Racetrack for Crypto Assets</h1>
            <h1 className="header1">The fastest crypto wins</h1>
          </div>
          <div className="subHeader">
            <p className="cen">
              Watch Races, Be a Fan, Have Fun
              <br />
              Bet on your favorite Crypto
              <br />
              Earn with the House <br />
              Own Racing Assets <br />
              Create, Sell and Collect NFTs
            </p>
            <div className="vid">
              <YouTubePlayer
                url="https://youtu.be/AIABoLjC8Uc"
                width={450}
                height={250}
              />
            </div>
          </div>
          <div className="btns">
            <a
              href="https://cryptoracetrack.gitbook.io/cryptoracetrack/"
              rel="noopener noreferrer"
              target="_blank"
              className="myButton"
            >
              Whitepaper
            </a>
            <a
              href="https://bscscan.com/token/0xeea509c221c5c6979e974f4501b4829be0ab2f8c"
              rel="noopener noreferrer"
              target="_blank"
              className="myButton"
            >
              Contract
            </a>
            <a
              href="https://www.pinksale.finance/launchpad/0xCdBF42169af12fdAA9a9C12C7469283350C847CE"
              className="myButton"
              rel="noopener noreferrer"
              target="_blank"
            >
              <p>Buy HOUZ on PinkSale</p>
              <img className="pinkSale" src={PinkSaleLogo} alt="pinksale-img" />
            </a>
            <a href="/" className="myButton">
              IDO2 (soon)
            </a>
          </div>
        </div>
      </section>
      <section id="About">
        <div className="about-section">
          <h1 className="heading-section">What is CryptoRaceTrack?</h1>
          <p className="about-text">
            We are the World's First Virtual Racetrack to Watch and Bet on
            Cryptocurrencies racing at the speed of their real-time price for a
            place at the podium.
            <br /> Jump into the CryptoRaceTrack, watch a race, win your bet, be
            a Fan, be a Creator, partner with the House, own CRT Assets, ... and
            make money having FUN!
          </p>
          <Model />
          <p className="about-text">
            Our dynamic Economy includes Play2Earn GameFi, Asset Ownership with
            Defi Stalking and Bonus Rewards, is Creator-Driven, has Fans-voting
            governance, and offers Experience2Earn Trophies for Fans to simply
            enjoy our EntertainFi Experience.
          </p>
        </div>
      </section>
      <section id="Experiences">
        <h1 className="expe-section">Experiences</h1>
        <div className="pick">
          <div className="expe-sec ">
            <div className="boxes buyH0U2">
              <p className="expe-text">
                Buy HOUZ token <br /> and Earn House profits
              </p>
            </div>
            <div className="boxes watch">
              <p className="expe-text">
                Watch Races, <br /> Be a Fan
              </p>
            </div>
            <div className="boxes bet">
              <p className="expe-text">
                Bet on your favorite Crypto <br /> (p2e GameFi)
              </p>
            </div>
          </div>
          <div className="expe-sec ">
            <div className="boxes ownTeam">
              <p className="expe-text">
                Own Racing Assets and <br /> earn stakes (DeFi)
              </p>
            </div>
            <div className="boxes ownTrack">
              <p className="expe-text">
                Collect experience rewards (ExperienceFi)
              </p>
            </div>
            <div className="boxes earn">
              <p className="expe-text">
                Earn in the Metaverse <br /> @ NFT Marketplace
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="Roadmap">
        <h1 className="expe-section">Roadmap</h1>
        <Roadmap />
        <div className="text-below">Full Roadmap 2022-2023</div>
      </section>
      <section id="Tokenomics">
        <h1 className="expe-section">Tokenomics</h1>
        <p className="toke-text">1,000,000,000 Total Supply</p>
        <img className="img-pieChart" src={PieChart} alt="Pie Chart" />
        <div className="btn-tok">
          <a
            href="https://cryptoracetrack.gitbook.io/cryptoracetrack/4.-tokenomics-for-houz-tokens"
            rel="noopener noreferrer"
            target="_blank"
            className="Button"
          >
            <div className="test">Whitepaper</div>
          </a>
        </div>
      </section>
      <section id="TokenSale">
        <div className="home-section pt">
          <h1 className="expe-section">Join the HOUZ token sale</h1>
          <div className="flex-token">
            <div className="">
              <img className="tokenImg" src={TokenImg} alt="tokenImg" />
            </div>
            <div className="sale-token">
              <p className="about-text">
                HOUZ tokens are now for sale to allow you to be a partner of the
                CryptoRaceTrack House. <br />
                The HOUZ token is a profit-participation token, and holders will
                win when the House wins. The more tokens you have, the bigger
                your cut after a race. <br /> <br />
              </p>
              <p className="subHeader bold">
                Partner with CryptoRaceTrack and make money the fun way!
              </p>
            </div>
          </div>
          <div className="btnSale">
            <a
              href="https://cryptoracetrack.gitbook.io/cryptoracetrack/"
              className="myButton"
              rel="noopener noreferrer"
              target="_blank"
            >
              Whitepaper
            </a>
            <a
              href="https://bscscan.com/token/0xeea509c221c5c6979e974f4501b4829be0ab2f8c"
              className="myButton"
              rel="noopener noreferrer"
              target="_blank"
            >
              Contract
            </a>
            <a
              href="https://www.pinksale.finance/launchpad/0xCdBF42169af12fdAA9a9C12C7469283350C847CE"
              className="myButton"
              rel="noopener noreferrer"
              target="_blank"
            >
              <p>Buy HOUZ on PinkSale</p>
              <img className="pinkSale" src={PinkSaleLogo} alt="pinksale-img" />
            </a>
            <a href="/" className="myButton">
              Soon
            </a>
          </div>
          <div className="tokenSpacing">
            <TokenSaleStages />
          </div>
          <p className="about-text">
            Only 20% of total supply is currently available for early adopters
            at this introductory price. <br /> For sale only through private
            sale and selected IDO launchapds to be announced soon.
          </p>
        </div>
      </section>
      <section id="Contact">
        <div className="home-section homie patn padnn">
          <h1 className="expe-section">Contact</h1>
          <div className="">
            <form id="survey-form" method="POST">
              <div className="form-group">
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="User Email"
                  required
                />
                <br /> <br />
                <select id="dropdown" className="control" name="role">
                  <option disabled selected value="Select help">
                    Subject
                  </option>
                  <option value="I want to invest privately">
                    I want to invest privately
                  </option>
                  <option value="More information please">
                    More information please
                  </option>
                  <option value="I need help to buy">I need help to buy</option>
                  <option value="Other">Other</option>
                </select>
                <br /> <br />
                <textarea
                  className="input-textarea"
                  name="Any comments or suggestions?"
                  placeholder="Message"
                  rows="5"
                  cols="30"
                ></textarea>
                <br />
                <Mailto email="support@cryptoracetrack.com" obfuscate={true}>
                  <input
                    id="submit"
                    className="btnSubmit"
                    type="submit"
                    name="submit"
                  ></input>
                </Mailto>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
