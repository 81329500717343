import React from "react";
import Layout from "../Main/Utilities/Layout";

import Profile1 from "../Static/profile1.svg";

import "../Styles/team.css";

export default function Team() {
  return (
    <Layout>
      <section id="team">
        <div className="team-content">
          <h1 className="texts1">Team</h1>
          <div className="team-profiles">
            <div className="profile">
              <img src={Profile1} alt="profile pics" />
              <p className="textPro">Team Participant</p>
              <div className="contents-pro">
                <p className="profileText">CEO</p>
                <a href="/" className="pro-link" rel="noopener noreferrer" target="_blank">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
            <div className="profile">
              <img src={Profile1} alt="profile pics" />
              <p className="textPro">Team Participant</p>
              <div className="contents-pro">
                <p className="profileText">CEO</p>
                <a href="/" className="pro-link" rel="noopener noreferrer" target="_blank">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
            <div className="profile">
              <img src={Profile1} alt="profile pics" />
              <p className="textPro">Team Participant</p>
              <div className="contents-pro">
                <p className="profileText">CEO</p>
                <a href="/" className="pro-link" rel="noopener noreferrer" target="_blank">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
            <div className="profile">
              <img src={Profile1} alt="profile pics" />
              <p className="textPro">Team Participant</p>
              <div className="contents-pro">
                <p className="profileText">CEO</p>
                <a href="/" className="pro-link" rel="noopener noreferrer" target="_blank">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
