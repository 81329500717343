import React, { Component } from "react";

import Homepage from "./Main/Homepage";
import Layout from "./Main/Utilities/Layout";
import FAQ from "./Pages/FAQ";
import Team from "./Pages/Team";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// CSS import
import "./App.css";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route
            exact
            caseSensitive={false}
            path="/"
            element={
              <Layout>
                <Homepage />
              </Layout>
            }
          />
          <Route exact caseSensitive={false} path="/faq" element={<FAQ />} />
          <Route exact caseSensitive={false} path="/team" element={<Team />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
